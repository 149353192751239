export const sequentialColors = {
  sapUiChartAccent1: '#5899DA',
  sapUiChartAccent2: '#E8743B',
  sapUiChartAccent3: '#19A979',
  sapUiChartAccent4: '#ED4A7B',
  sapUiChartAccent5: '#945ECF',
  sapUiChartAccent6: '#13A4B4',
  sapUiChartAccent7: '#525DF4',
  sapUiChartAccent8: '#BF399E',
  sapUiChartAccent9: '#6C8893',
  sapUiChartAccent10: '#EE6868',
  sapUiChartAccent11: '#2F6497',
  sapUiChartAccent12: '#1866b4'
};

export const semanticColors = {
  sapUiChartGood: '#3fa45b',
  sapUiChartBad: '#dc0d0e',
  sapUiChartHighlight: '#de890d'
};
