/*
 * ### WARNING ###
 * This is an autogenerated file, do not change manually.
 * In order to recreate this file, please run 'node packages/base/scripts/cssVariables/parse.js'
 */

export const sapFontFamily = 'var(--sapFontFamily)';
export const sapFontSize = 'var(--sapFontSize)';
export const sapCompanyLogo = 'var(--sapCompanyLogo)';
export const sapBackgroundImage = 'var(--sapBackgroundImage)';
export const sapBackgroundImageOpacity = 'var(--sapBackgroundImageOpacity)';
export const sapBackgroundImageRepeat = 'var(--sapBackgroundImageRepeat)';
export const sapContent_GridSize = 'var(--sapContent_GridSize)';
export const sapPrimary1 = 'var(--sapPrimary1)';
export const sapPrimary2 = 'var(--sapPrimary2)';
export const sapPrimary3 = 'var(--sapPrimary3)';
export const sapPrimary4 = 'var(--sapPrimary4)';
export const sapPrimary5 = 'var(--sapPrimary5)';
export const sapPrimary6 = 'var(--sapPrimary6)';
export const sapPrimary7 = 'var(--sapPrimary7)';
export const sapAccentColor1 = 'var(--sapAccentColor1)';
export const sapAccentColor2 = 'var(--sapAccentColor2)';
export const sapAccentColor3 = 'var(--sapAccentColor3)';
export const sapAccentColor4 = 'var(--sapAccentColor4)';
export const sapAccentColor5 = 'var(--sapAccentColor5)';
export const sapAccentColor6 = 'var(--sapAccentColor6)';
export const sapAccentColor7 = 'var(--sapAccentColor7)';
export const sapAccentColor8 = 'var(--sapAccentColor8)';
export const sapAccentColor9 = 'var(--sapAccentColor9)';
export const sapAccentColor10 = 'var(--sapAccentColor10)';
export const sapNegativeColor = 'var(--sapNegativeColor)';
export const sapCriticalColor = 'var(--sapCriticalColor)';
export const sapPositiveColor = 'var(--sapPositiveColor)';
export const sapInformativeColor = 'var(--sapInformativeColor)';
export const sapNeutralColor = 'var(--sapNeutralColor)';
export const sapIndicationColor_1 = 'var(--sapIndicationColor_1)';
export const sapIndicationColor_2 = 'var(--sapIndicationColor_2)';
export const sapIndicationColor_3 = 'var(--sapIndicationColor_3)';
export const sapIndicationColor_4 = 'var(--sapIndicationColor_4)';
export const sapIndicationColor_5 = 'var(--sapIndicationColor_5)';
export const sapElement_LineHeight = 'var(--sapElement_LineHeight)';
export const sapElement_Height = 'var(--sapElement_Height)';
export const sapElement_BorderWidth = 'var(--sapElement_BorderWidth)';
export const sapContent_LineHeight = 'var(--sapContent_LineHeight)';
export const sapContent_ElementHeight = 'var(--sapContent_ElementHeight)';
export const sapContent_ElementHeight_PX = 'var(--sapContent_ElementHeight_PX)';
export const sapContent_FocusColor = 'var(--sapContent_FocusColor)';
export const sapContent_ContrastFocusColor = 'var(--sapContent_ContrastFocusColor)';
export const sapContent_ShadowColor = 'var(--sapContent_ShadowColor)';
export const sapContent_ContrastShadowColor = 'var(--sapContent_ContrastShadowColor)';
export const sapContent_SearchHighlightColor = 'var(--sapContent_SearchHighlightColor)';
export const sapContent_HelpColor = 'var(--sapContent_HelpColor)';
export const sapContent_MonospaceFontFamily = 'var(--sapContent_MonospaceFontFamily)';
export const sapContent_DisabledOpacity = 'var(--sapContent_DisabledOpacity)';
export const sapContent_ContrastTextThreshold = 'var(--sapContent_ContrastTextThreshold)';
export const sapContent_ContrastTextColor = 'var(--sapContent_ContrastTextColor)';
export const sapShell_BackgroundImage = 'var(--sapShell_BackgroundImage)';
export const sapShell_BackgroundImageOpacity = 'var(--sapShell_BackgroundImageOpacity)';
export const sapShell_BackgroundImageRepeat = 'var(--sapShell_BackgroundImageRepeat)';
export const sapShell_BackgroundPatternColor = 'var(--sapShell_BackgroundPatternColor)';
export const sapShell_BackgroundGradient = 'var(--sapShell_BackgroundGradient)';
export const sapShell_InteractiveTextColor = 'var(--sapShell_InteractiveTextColor)';
export const sapShell_Favicon = 'var(--sapShell_Favicon)';
export const sapButton_BorderCornerRadius = 'var(--sapButton_BorderCornerRadius)';
export const sapField_BorderCornerRadius = 'var(--sapField_BorderCornerRadius)';
export const sapGroup_TitleBackground = 'var(--sapGroup_TitleBackground)';
export const sapGroup_BorderCornerRadius = 'var(--sapGroup_BorderCornerRadius)';
export const sapGroup_FooterBackground = 'var(--sapGroup_FooterBackground)';
export const sapToolbar_Background = 'var(--sapToolbar_Background)';
export const sapScrollBar_Dimension = 'var(--sapScrollBar_Dimension)';
export const sapBlockLayer_Background = 'var(--sapBlockLayer_Background)';
export const sapTile_BorderColor = 'var(--sapTile_BorderColor)';
export const sapUiFontSize = 'var(--sapUiFontSize)';
export const sapUiFontCondensedFamily = 'var(--sapUiFontCondensedFamily)';
export const sapUiFontHeaderWeight = 'var(--sapUiFontHeaderWeight)';
export const sapMFontHeader1Size = 'var(--sapMFontHeader1Size)';
export const sapMFontHeader2Size = 'var(--sapMFontHeader2Size)';
export const sapMFontHeader3Size = 'var(--sapMFontHeader3Size)';
export const sapMFontHeader4Size = 'var(--sapMFontHeader4Size)';
export const sapMFontHeader5Size = 'var(--sapMFontHeader5Size)';
export const sapMFontHeader6Size = 'var(--sapMFontHeader6Size)';
export const sapMFontSmallSize = 'var(--sapMFontSmallSize)';
export const sapMFontMediumSize = 'var(--sapMFontMediumSize)';
export const sapMFontLargeSize = 'var(--sapMFontLargeSize)';
export const sapUiTranslucentBGOpacity = 'var(--sapUiTranslucentBGOpacity)';
export const sapMPlatformDependent = 'var(--sapMPlatformDependent)';
export const sapUiDesktopFontSize = 'var(--sapUiDesktopFontSize)';
export const sapUiFontLargeSize = 'var(--sapUiFontLargeSize)';
export const sapUiFontSmallSize = 'var(--sapUiFontSmallSize)';
export const sapUiFontHeader1Size = 'var(--sapUiFontHeader1Size)';
export const sapUiFontHeader2Size = 'var(--sapUiFontHeader2Size)';
export const sapUiFontHeader3Size = 'var(--sapUiFontHeader3Size)';
export const sapUiFontHeader4Size = 'var(--sapUiFontHeader4Size)';
export const sapUiFontHeader5Size = 'var(--sapUiFontHeader5Size)';
export const sapUiLineHeight = 'var(--sapUiLineHeight)';
export const sapUiNotifierSeparatorWidth = 'var(--sapUiNotifierSeparatorWidth)';
export const sapUiButtonEmphasizedTextShadow = 'var(--sapUiButtonEmphasizedTextShadow)';
export const sapUiButtonLiteBackground = 'var(--sapUiButtonLiteBackground)';
export const sapUiButtonLiteBorderColor = 'var(--sapUiButtonLiteBorderColor)';
export const sapUiListTableTextSize = 'var(--sapUiListTableTextSize)';
export const sapUiListTableIconSize = 'var(--sapUiListTableIconSize)';
export const sap_wc_input_icon_min_width = 'var(--sap_wc_input_icon_min_width)';
export const sap_wc_input_compact_min_width = 'var(--sap_wc_input_compact_min_width)';
export const sap_wc_input_disabled_opacity = 'var(--sap_wc_input_disabled_opacity)';
export const sapShellColor = 'var(--sapShellColor)';
export const sapBrandColor = 'var(--sapBrandColor)';
export const sapLinkColor = 'var(--sapLinkColor)';
export const sapBaseColor = 'var(--sapBaseColor)';
export const sapTextColor = 'var(--sapTextColor)';
export const sapErrorBorderColor = 'var(--sapErrorBorderColor)';
export const sapNegativeElementColor = 'var(--sapNegativeElementColor)';
export const sapNegativeTextColor = 'var(--sapNegativeTextColor)';
export const sapWarningBorderColor = 'var(--sapWarningBorderColor)';
export const sapCriticalElementColor = 'var(--sapCriticalElementColor)';
export const sapCriticalTextColor = 'var(--sapCriticalTextColor)';
export const sapSuccessBorderColor = 'var(--sapSuccessBorderColor)';
export const sapPositiveElementColor = 'var(--sapPositiveElementColor)';
export const sapPositiveTextColor = 'var(--sapPositiveTextColor)';
export const sapInformationBorderColor = 'var(--sapInformationBorderColor)';
export const sapInformativeElementColor = 'var(--sapInformativeElementColor)';
export const sapNeutralBorderColor = 'var(--sapNeutralBorderColor)';
export const sapNeutralElementColor = 'var(--sapNeutralElementColor)';
export const sapNeutralTextColor = 'var(--sapNeutralTextColor)';
export const sapErrorColor = 'var(--sapErrorColor)';
export const sapWarningColor = 'var(--sapWarningColor)';
export const sapSuccessColor = 'var(--sapSuccessColor)';
export const sapInformationColor = 'var(--sapInformationColor)';
export const sapContent_IconHeight = 'var(--sapContent_IconHeight)';
export const sapContent_NonInteractiveIconColor = 'var(--sapContent_NonInteractiveIconColor)';
export const sapContent_LabelColor = 'var(--sapContent_LabelColor)';
export const sapContent_ContrastIconColor = 'var(--sapContent_ContrastIconColor)';
export const sapContent_ForegroundBorderColor = 'var(--sapContent_ForegroundBorderColor)';
export const sapShell_Background = 'var(--sapShell_Background)';
export const sapButton_BorderWidth = 'var(--sapButton_BorderWidth)';
export const sapField_BorderColor = 'var(--sapField_BorderColor)';
export const sapField_BorderWidth = 'var(--sapField_BorderWidth)';
export const sapGroup_Title_FontSize = 'var(--sapGroup_Title_FontSize)';
export const sapGroup_BorderWidth = 'var(--sapGroup_BorderWidth)';
export const sapList_BorderWidth = 'var(--sapList_BorderWidth)';
export const sapUiFontFamily = 'var(--sapUiFontFamily)';
export const sapUiGlobalLogo = 'var(--sapUiGlobalLogo)';
export const sapUiGlobalBackgroundImage = 'var(--sapUiGlobalBackgroundImage)';
export const sapUiBackgroundImage = 'var(--sapUiBackgroundImage)';
export const sapUiUx3ShellBackgroundImageURL = 'var(--sapUiUx3ShellBackgroundImageURL)';
export const sapUiGlobalBackgroundImageOpacity = 'var(--sapUiGlobalBackgroundImageOpacity)';
export const sapUiGlobalBackgroundRepeat = 'var(--sapUiGlobalBackgroundRepeat)';
export const sapUiElementLineHeight = 'var(--sapUiElementLineHeight)';
export const sapUiElementHeight = 'var(--sapUiElementHeight)';
export const sapUiElementBorderWidth = 'var(--sapUiElementBorderWidth)';
export const sapUiContentLineHeight = 'var(--sapUiContentLineHeight)';
export const sapUiContentElementHeight = 'var(--sapUiContentElementHeight)';
export const sapUiContentElementHeightPX = 'var(--sapUiContentElementHeightPX)';
export const sapUiContentFocusColor = 'var(--sapUiContentFocusColor)';
export const sapUiContentContrastFocusColor = 'var(--sapUiContentContrastFocusColor)';
export const sapUiContentShadowColor = 'var(--sapUiContentShadowColor)';
export const sapUiContentContrastShadowColor = 'var(--sapUiContentContrastShadowColor)';
export const sapUiContentSearchHighlightColor = 'var(--sapUiContentSearchHighlightColor)';
export const sapUiContentHelpColor = 'var(--sapUiContentHelpColor)';
export const sapUiContentDisabledOpacity = 'var(--sapUiContentDisabledOpacity)';
export const sapUiContentContrastTextThreshold = 'var(--sapUiContentContrastTextThreshold)';
export const sapUiContentContrastTextColor = 'var(--sapUiContentContrastTextColor)';
export const sapUiShellBackgroundImage = 'var(--sapUiShellBackgroundImage)';
export const sapUiShellBackgroundImageOpacity = 'var(--sapUiShellBackgroundImageOpacity)';
export const sapUiShellBackgroundImageRepeat = 'var(--sapUiShellBackgroundImageRepeat)';
export const sapUiShellBackgroundPatternColor = 'var(--sapUiShellBackgroundPatternColor)';
export const sapUiShellBackgroundGradient = 'var(--sapUiShellBackgroundGradient)';
export const sapUiShellInteractiveTextColor = 'var(--sapUiShellInteractiveTextColor)';
export const sapUiShellFavicon = 'var(--sapUiShellFavicon)';
export const sapUiButtonBorderCornerRadius = 'var(--sapUiButtonBorderCornerRadius)';
export const sapUiFieldBorderCornerRadius = 'var(--sapUiFieldBorderCornerRadius)';
export const sapUiGroupTitleBackground = 'var(--sapUiGroupTitleBackground)';
export const sapUiGroupFooterBackground = 'var(--sapUiGroupFooterBackground)';
export const sapUiToolbarBackground = 'var(--sapUiToolbarBackground)';
export const sapUiScrollBarDimension = 'var(--sapUiScrollBarDimension)';
export const sapUiBlockLayerBackground = 'var(--sapUiBlockLayerBackground)';
export const sapUiTileBorderColor = 'var(--sapUiTileBorderColor)';
export const sapUiContentGridSize = 'var(--sapUiContentGridSize)';
export const sapUiPrimary1 = 'var(--sapUiPrimary1)';
export const sapUiPrimary2 = 'var(--sapUiPrimary2)';
export const sapUiPrimary3 = 'var(--sapUiPrimary3)';
export const sapUiPrimary4 = 'var(--sapUiPrimary4)';
export const sapUiPrimary5 = 'var(--sapUiPrimary5)';
export const sapUiPrimary6 = 'var(--sapUiPrimary6)';
export const sapUiPrimary7 = 'var(--sapUiPrimary7)';
export const sapUiAccent1 = 'var(--sapUiAccent1)';
export const sapUiAccent2 = 'var(--sapUiAccent2)';
export const sapUiAccent3 = 'var(--sapUiAccent3)';
export const sapUiAccent4 = 'var(--sapUiAccent4)';
export const sapUiAccent5 = 'var(--sapUiAccent5)';
export const sapUiAccent6 = 'var(--sapUiAccent6)';
export const sapUiAccent7 = 'var(--sapUiAccent7)';
export const sapUiAccent8 = 'var(--sapUiAccent8)';
export const sapUiAccent9 = 'var(--sapUiAccent9)';
export const sapUiAccent10 = 'var(--sapUiAccent10)';
export const sapUiNeutralBorder = 'var(--sapUiNeutralBorder)';
export const sapUiNegative = 'var(--sapUiNegative)';
export const sapUiCritical = 'var(--sapUiCritical)';
export const sapUiPositive = 'var(--sapUiPositive)';
export const sapUiInformative = 'var(--sapUiInformative)';
export const sapUiNeutral = 'var(--sapUiNeutral)';
export const sapUiIndication1 = 'var(--sapUiIndication1)';
export const sapUiIndication2 = 'var(--sapUiIndication2)';
export const sapUiIndication3 = 'var(--sapUiIndication3)';
export const sapUiIndication4 = 'var(--sapUiIndication4)';
export const sapUiIndication5 = 'var(--sapUiIndication5)';
export const sapUiFontHeader6Size = 'var(--sapUiFontHeader6Size)';
export const sapShell_BorderColor = 'var(--sapShell_BorderColor)';
export const sapUiShellColor = 'var(--sapUiShellColor)';
export const sapButton_Emphasized_Background = 'var(--sapButton_Emphasized_Background)';
export const sapUiBrand = 'var(--sapUiBrand)';
export const sapUiLink = 'var(--sapUiLink)';
export const sapContent_ImagePlaceholderForegroundColor = 'var(--sapContent_ImagePlaceholderForegroundColor)';
export const sapButton_Background = 'var(--sapButton_Background)';
export const sapField_Background = 'var(--sapField_Background)';
export const sapGroup_ContentBackground = 'var(--sapGroup_ContentBackground)';
export const sapList_Background = 'var(--sapList_Background)';
export const sapPageHeader_Background = 'var(--sapPageHeader_Background)';
export const sapPageFooter_Background = 'var(--sapPageFooter_Background)';
export const sapObjectHeader_Background = 'var(--sapObjectHeader_Background)';
export const sapTile_Background = 'var(--sapTile_Background)';
export const sapUiBaseColor = 'var(--sapUiBaseColor)';
export const sapTitleColor = 'var(--sapTitleColor)';
export const sapField_TextColor = 'var(--sapField_TextColor)';
export const sapUiBaseText = 'var(--sapUiBaseText)';
export const sapField_InvalidColor = 'var(--sapField_InvalidColor)';
export const sapUiErrorBorder = 'var(--sapUiErrorBorder)';
export const sapUiNegativeElement = 'var(--sapUiNegativeElement)';
export const sapUiNegativeText = 'var(--sapUiNegativeText)';
export const sapField_WarningColor = 'var(--sapField_WarningColor)';
export const sapUiWarningBorder = 'var(--sapUiWarningBorder)';
export const sapUiCriticalElement = 'var(--sapUiCriticalElement)';
export const sapUiCriticalText = 'var(--sapUiCriticalText)';
export const sapField_SuccessColor = 'var(--sapField_SuccessColor)';
export const sapUiSuccessBorder = 'var(--sapUiSuccessBorder)';
export const sapUiPositiveElement = 'var(--sapUiPositiveElement)';
export const sapUiPositiveText = 'var(--sapUiPositiveText)';
export const sapUiInformationBorder = 'var(--sapUiInformationBorder)';
export const sapUiInformativeElement = 'var(--sapUiInformativeElement)';
export const sapUiNeutralElement = 'var(--sapUiNeutralElement)';
export const sapUiNeutralText = 'var(--sapUiNeutralText)';
export const sapUiContentIconHeight = 'var(--sapUiContentIconHeight)';
export const sapUiContentNonInteractiveIconColor = 'var(--sapUiContentNonInteractiveIconColor)';
export const sapUiContentLabelColor = 'var(--sapUiContentLabelColor)';
export const sapUiContentContrastIconColor = 'var(--sapUiContentContrastIconColor)';
export const sapUiContentForegroundBorderColor = 'var(--sapUiContentForegroundBorderColor)';
export const sapUiShellBackground = 'var(--sapUiShellBackground)';
export const sapUiButtonBorderWidth = 'var(--sapUiButtonBorderWidth)';
export const sapField_Focus_BorderColor = 'var(--sapField_Focus_BorderColor)';
export const sapField_ReadOnly_BorderColor = 'var(--sapField_ReadOnly_BorderColor)';
export const sapUiFieldBorderColor = 'var(--sapUiFieldBorderColor)';
export const sapUiFieldBorderWidth = 'var(--sapUiFieldBorderWidth)';
export const sapUiGroupBorderWidth = 'var(--sapUiGroupBorderWidth)';
export const sapUiListBorderWidth = 'var(--sapUiListBorderWidth)';
export const sapUiFontHeaderFamily = 'var(--sapUiFontHeaderFamily)';
export const sapUiDesktopFontFamily = 'var(--sapUiDesktopFontFamily)';
export const sapUiShadowText = 'var(--sapUiShadowText)';
export const sapUiFieldActiveTextColor = 'var(--sapUiFieldActiveTextColor)';
export const sapUiCalendarColorToday = 'var(--sapUiCalendarColorToday)';
export const sapUiShellBorderColor = 'var(--sapUiShellBorderColor)';
export const sapButton_Emphasized_BorderColor = 'var(--sapButton_Emphasized_BorderColor)';
export const sapUiButtonEmphasizedBackground = 'var(--sapUiButtonEmphasizedBackground)';
export const sapUiLinkActive = 'var(--sapUiLinkActive)';
export const sapUiLinkVisited = 'var(--sapUiLinkVisited)';
export const sapUiDragAndDropActiveColor = 'var(--sapUiDragAndDropActiveColor)';
export const sapUiDragAndDropActiveBorderColor = 'var(--sapUiDragAndDropActiveBorderColor)';
export const sapUiContentImagePlaceholderForegroundColor = 'var(--sapUiContentImagePlaceholderForegroundColor)';
export const sapButton_Reject_Background = 'var(--sapButton_Reject_Background)';
export const sapButton_Accept_Background = 'var(--sapButton_Accept_Background)';
export const sapUiButtonBackground = 'var(--sapUiButtonBackground)';
export const sapField_HelpBackground = 'var(--sapField_HelpBackground)';
export const sapField_Hover_Background = 'var(--sapField_Hover_Background)';
export const sapField_Focus_Background = 'var(--sapField_Focus_Background)';
export const sapField_InvalidBackground = 'var(--sapField_InvalidBackground)';
export const sapField_WarningBackground = 'var(--sapField_WarningBackground)';
export const sapField_SuccessBackground = 'var(--sapField_SuccessBackground)';
export const sapUiFieldBackground = 'var(--sapUiFieldBackground)';
export const sapUiGroupContentBackground = 'var(--sapUiGroupContentBackground)';
export const sapUiListBackground = 'var(--sapUiListBackground)';
export const sapUiPageHeaderBackground = 'var(--sapUiPageHeaderBackground)';
export const sapUiPageFooterBackground = 'var(--sapUiPageFooterBackground)';
export const sapUiObjectHeaderBackground = 'var(--sapUiObjectHeaderBackground)';
export const sapUiTileBackground = 'var(--sapUiTileBackground)';
export const sapUiTextTitle = 'var(--sapUiTextTitle)';
export const sapUiFieldTextColor = 'var(--sapUiFieldTextColor)';
export const sapUiListTextColor = 'var(--sapUiListTextColor)';
export const sapUiFieldInvalidColor = 'var(--sapUiFieldInvalidColor)';
export const sapUiButtonRejectBorderColor = 'var(--sapUiButtonRejectBorderColor)';
export const sapUiFieldWarningColor = 'var(--sapUiFieldWarningColor)';
export const sapUiFieldSuccessColor = 'var(--sapUiFieldSuccessColor)';
export const sapUiButtonAcceptBorderColor = 'var(--sapUiButtonAcceptBorderColor)';
export const sapUiDragAndDropColor = 'var(--sapUiDragAndDropColor)';
export const sapUiDragAndDropBorderColor = 'var(--sapUiDragAndDropBorderColor)';
export const sapUiFieldFocusBorderColor = 'var(--sapUiFieldFocusBorderColor)';
export const sapUiFieldReadOnlyBorderColor = 'var(--sapUiFieldReadOnlyBorderColor)';
export const sapUiButtonEmphasizedBorderColor = 'var(--sapUiButtonEmphasizedBorderColor)';
export const sapUiButtonRejectBackground = 'var(--sapUiButtonRejectBackground)';
export const sapUiButtonAcceptBackground = 'var(--sapUiButtonAcceptBackground)';
export const sapUiSegmentedButtonBackground = 'var(--sapUiSegmentedButtonBackground)';
export const sapUiFieldHelpBackground = 'var(--sapUiFieldHelpBackground)';
export const sapUiFieldHoverBackground = 'var(--sapUiFieldHoverBackground)';
export const sapField_Focus_HelpBackground = 'var(--sapField_Focus_HelpBackground)';
export const sapUiFieldFocusBackground = 'var(--sapUiFieldFocusBackground)';
export const sapUiFieldInvalidBackground = 'var(--sapUiFieldInvalidBackground)';
export const sapUiFieldWarningBackground = 'var(--sapUiFieldWarningBackground)';
export const sapUiFieldSuccessBackground = 'var(--sapUiFieldSuccessBackground)';
export const sapUiShellContainerBackground = 'var(--sapUiShellContainerBackground)';
export const sapUiDragAndDropBackground = 'var(--sapUiDragAndDropBackground)';
export const sapUiButtonActionSelectBackground = 'var(--sapUiButtonActionSelectBackground)';
export const sapUiListGroupHeaderBackground = 'var(--sapUiListGroupHeaderBackground)';
export const sapUiShellGroupTextColor = 'var(--sapUiShellGroupTextColor)';
export const sapUiListFooterTextColor = 'var(--sapUiListFooterTextColor)';
export const sapUiButtonRejectHoverBorderColor = 'var(--sapUiButtonRejectHoverBorderColor)';
export const sapUiButtonAcceptHoverBorderColor = 'var(--sapUiButtonAcceptHoverBorderColor)';
export const sapUiFieldFocusHelpBackground = 'var(--sapUiFieldFocusHelpBackground)';
export const sapUiShellAltContainerBackground = 'var(--sapUiShellAltContainerBackground)';
export const sapHighlightColor = 'var(--sapHighlightColor)';
export const sapBackgroundColorDefault = 'var(--sapBackgroundColorDefault)';
export const sapBackgroundColor = 'var(--sapBackgroundColor)';
export const sapErrorBackground = 'var(--sapErrorBackground)';
export const sapWarningBackground = 'var(--sapWarningBackground)';
export const sapSuccessBackground = 'var(--sapSuccessBackground)';
export const sapInformationBackground = 'var(--sapInformationBackground)';
export const sapNeutralBackground = 'var(--sapNeutralBackground)';
export const sapInformativeTextColor = 'var(--sapInformativeTextColor)';
export const sapContent_MarkerIconColor = 'var(--sapContent_MarkerIconColor)';
export const sapContent_MarkerTextColor = 'var(--sapContent_MarkerTextColor)';
export const sapContent_ImagePlaceholderBackground = 'var(--sapContent_ImagePlaceholderBackground)';
export const sapContent_DisabledTextColor = 'var(--sapContent_DisabledTextColor)';
export const sapContent_ForegroundColor = 'var(--sapContent_ForegroundColor)';
export const sapContent_BadgeBackground = 'var(--sapContent_BadgeBackground)';
export const sapButton_BorderColor = 'var(--sapButton_BorderColor)';
export const sapField_RequiredColor = 'var(--sapField_RequiredColor)';
export const sapGroup_TitleBorderColor = 'var(--sapGroup_TitleBorderColor)';
export const sapScrollBar_FaceColor = 'var(--sapScrollBar_FaceColor)';
export const sapInfobar_Background = 'var(--sapInfobar_Background)';
export const sapToolbar_SeparatorColor = 'var(--sapToolbar_SeparatorColor)';
export const sapUiNotificationBarBG = 'var(--sapUiNotificationBarBG)';
export const sapUiNotifierSeparator = 'var(--sapUiNotifierSeparator)';
export const sapUiNotificationBarBorder = 'var(--sapUiNotificationBarBorder)';
export const sapUiContentShadowColorFade30 = 'var(--sapUiContentShadowColorFade30)';
export const sapUiContentShadowColorFade5 = 'var(--sapUiContentShadowColorFade5)';
export const sapUiContentShadowColorFade15 = 'var(--sapUiContentShadowColorFade15)';
export const sapUiAccent1Lighten50 = 'var(--sapUiAccent1Lighten50)';
export const sapUiAccent2Lighten40 = 'var(--sapUiAccent2Lighten40)';
export const sapUiAccent3Lighten46 = 'var(--sapUiAccent3Lighten46)';
export const sapUiAccent4Lighten46 = 'var(--sapUiAccent4Lighten46)';
export const sapUiAccent5Lighten32 = 'var(--sapUiAccent5Lighten32)';
export const sapUiAccent6Lighten52 = 'var(--sapUiAccent6Lighten52)';
export const sapUiAccent7Lighten64 = 'var(--sapUiAccent7Lighten64)';
export const sapUiAccent8Lighten61 = 'var(--sapUiAccent8Lighten61)';
export const sapUiAccent9Lighten37 = 'var(--sapUiAccent9Lighten37)';
export const sapUiAccent10Lighten49 = 'var(--sapUiAccent10Lighten49)';
export const sapUiShellHoverBackground = 'var(--sapUiShellHoverBackground)';
export const sapUiShellActiveBackground = 'var(--sapUiShellActiveBackground)';
export const sapUiShellHoverToggleBackground = 'var(--sapUiShellHoverToggleBackground)';
export const sapUiLinkHover = 'var(--sapUiLinkHover)';
export const sapUiDragAndDropActiveBackground = 'var(--sapUiDragAndDropActiveBackground)';
export const sapUiLinkDarken15 = 'var(--sapUiLinkDarken15)';
export const sapGroup_ContentBorderColor = 'var(--sapGroup_ContentBorderColor)';
export const sapList_BorderColor = 'var(--sapList_BorderColor)';
export const sapList_HeaderBackground = 'var(--sapList_HeaderBackground)';
export const sapPageHeader_BorderColor = 'var(--sapPageHeader_BorderColor)';
export const sapUiButtonRejectActiveBackground = 'var(--sapUiButtonRejectActiveBackground)';
export const sapUiButtonAcceptActiveBackground = 'var(--sapUiButtonAcceptActiveBackground)';
export const sapUiFieldPlaceholderTextColor = 'var(--sapUiFieldPlaceholderTextColor)';
export const sapSelectedColor = 'var(--sapSelectedColor)';
export const sapActiveColor = 'var(--sapActiveColor)';
export const sapContent_IconColor = 'var(--sapContent_IconColor)';
export const sapField_Hover_BorderColor = 'var(--sapField_Hover_BorderColor)';
export const sapList_HighlightColor = 'var(--sapList_HighlightColor)';
export const sapUiHighlight = 'var(--sapUiHighlight)';
export const sapUiBaseBG = 'var(--sapUiBaseBG)';
export const sapUiGlobalBackgroundColor = 'var(--sapUiGlobalBackgroundColor)';
export const sapUiErrorBG = 'var(--sapUiErrorBG)';
export const sapUiWarningBG = 'var(--sapUiWarningBG)';
export const sapUiSuccessBG = 'var(--sapUiSuccessBG)';
export const sapUiInformationBG = 'var(--sapUiInformationBG)';
export const sapUiNeutralBG = 'var(--sapUiNeutralBG)';
export const sapUiInformativeText = 'var(--sapUiInformativeText)';
export const sapUiContentMarkerIconColor = 'var(--sapUiContentMarkerIconColor)';
export const sapUiContentMarkerTextColor = 'var(--sapUiContentMarkerTextColor)';
export const sapUiContentImagePlaceholderBackground = 'var(--sapUiContentImagePlaceholderBackground)';
export const sapUiContentDisabledTextColor = 'var(--sapUiContentDisabledTextColor)';
export const sapUiContentForegroundColor = 'var(--sapUiContentForegroundColor)';
export const sapUiContentBadgeBackground = 'var(--sapUiContentBadgeBackground)';
export const sapButton_Hover_BorderColor = 'var(--sapButton_Hover_BorderColor)';
export const sapUiButtonBorderColor = 'var(--sapUiButtonBorderColor)';
export const sapUiFieldRequiredColor = 'var(--sapUiFieldRequiredColor)';
export const sapUiGroupTitleBorderColor = 'var(--sapUiGroupTitleBorderColor)';
export const sapScrollBar_BorderColor = 'var(--sapScrollBar_BorderColor)';
export const sapUiScrollBarFaceColor = 'var(--sapUiScrollBarFaceColor)';
export const sapUiInfobarBackground = 'var(--sapUiInfobarBackground)';
export const sapUiToolbarSeparatorColor = 'var(--sapUiToolbarSeparatorColor)';
export const sapUiShellBorderColorLighten30 = 'var(--sapUiShellBorderColorLighten30)';
export const sapUiButtonEmphasizedHoverBackground = 'var(--sapUiButtonEmphasizedHoverBackground)';
export const sapUiButtonEmphasizedActiveBackground = 'var(--sapUiButtonEmphasizedActiveBackground)';
export const sapUiButtonBackgroundDarken24 = 'var(--sapUiButtonBackgroundDarken24)';
export const sapUiButtonBackgroundDarken7 = 'var(--sapUiButtonBackgroundDarken7)';
export const sapUiButtonBackgroundDarken2 = 'var(--sapUiButtonBackgroundDarken2)';
export const sapUiButtonBackgroundDarken10 = 'var(--sapUiButtonBackgroundDarken10)';
export const sapUiGroupContentBorderColor = 'var(--sapUiGroupContentBorderColor)';
export const sapUiListFooterBackground = 'var(--sapUiListFooterBackground)';
export const sapUiListTableGroupHeaderBackground = 'var(--sapUiListTableGroupHeaderBackground)';
export const sapUiListBackgroundDarken3 = 'var(--sapUiListBackgroundDarken3)';
export const sapUiListBackgroundDarken10 = 'var(--sapUiListBackgroundDarken10)';
export const sapUiListBackgroundDarken13 = 'var(--sapUiListBackgroundDarken13)';
export const sapUiListBackgroundDarken15 = 'var(--sapUiListBackgroundDarken15)';
export const sapUiListBackgroundDarken20 = 'var(--sapUiListBackgroundDarken20)';
export const sapList_HeaderBorderColor = 'var(--sapList_HeaderBorderColor)';
export const sapUiListBorderColor = 'var(--sapUiListBorderColor)';
export const sapUiListHeaderBackground = 'var(--sapUiListHeaderBackground)';
export const sapUiPageHeaderBorderColor = 'var(--sapUiPageHeaderBorderColor)';
export const sapUiObjectHeaderBorderColor = 'var(--sapUiObjectHeaderBorderColor)';
export const sapUiTileBackgroundDarken20 = 'var(--sapUiTileBackgroundDarken20)';
export const sapUiButtonRejectActiveBorderColor = 'var(--sapUiButtonRejectActiveBorderColor)';
export const sapUiFieldWarningColorDarken100 = 'var(--sapUiFieldWarningColorDarken100)';
export const sapUiButtonAcceptActiveBorderColor = 'var(--sapUiButtonAcceptActiveBorderColor)';
export const sapUiSelected = 'var(--sapUiSelected)';
export const sapUiActive = 'var(--sapUiActive)';
export const sapScrollBar_SymbolColor = 'var(--sapScrollBar_SymbolColor)';
export const sapUiContentIconColor = 'var(--sapUiContentIconColor)';
export const sapUiFieldHoverBorderColor = 'var(--sapUiFieldHoverBorderColor)';
export const sapUiListHighlightColor = 'var(--sapUiListHighlightColor)';
export const sapUiButtonRejectHoverBackground = 'var(--sapUiButtonRejectHoverBackground)';
export const sapUiButtonAcceptHoverBackground = 'var(--sapUiButtonAcceptHoverBackground)';
export const sapUiButtonHoverBorderColor = 'var(--sapUiButtonHoverBorderColor)';
export const sapUiSegmentedButtonBorderColor = 'var(--sapUiSegmentedButtonBorderColor)';
export const sapUiScrollBarBorderColor = 'var(--sapUiScrollBarBorderColor)';
export const sapUiButtonEmphasizedHoverBorderColor = 'var(--sapUiButtonEmphasizedHoverBorderColor)';
export const sapUiButtonEmphasizedActiveBorderColor = 'var(--sapUiButtonEmphasizedActiveBorderColor)';
export const sapUiListHeaderBorderColor = 'var(--sapUiListHeaderBorderColor)';
export const sapUiButtonActionSelectBorderColor = 'var(--sapUiButtonActionSelectBorderColor)';
export const sapUiListVerticalBorderColor = 'var(--sapUiListVerticalBorderColor)';
export const sapUiListTableFooterBorder = 'var(--sapUiListTableFooterBorder)';
export const sapUiToggleButtonPressedBackground = 'var(--sapUiToggleButtonPressedBackground)';
export const sapUiButtonActiveBackground = 'var(--sapUiButtonActiveBackground)';
export const sapUiFieldActiveBackground = 'var(--sapUiFieldActiveBackground)';
export const sapUiFieldActiveBorderColor = 'var(--sapUiFieldActiveBorderColor)';
export const sapUiScrollBarSymbolColor = 'var(--sapUiScrollBarSymbolColor)';
export const sapUiListActiveBackground = 'var(--sapUiListActiveBackground)';
export const sapUiSegmentedButtonFooterBorderColor = 'var(--sapUiSegmentedButtonFooterBorderColor)';
export const sapUiToggleButtonPressedBorderColor = 'var(--sapUiToggleButtonPressedBorderColor)';
export const sapUiSegmentedButtonSelectedBackground = 'var(--sapUiSegmentedButtonSelectedBackground)';
export const sapUiButtonActiveBorderColor = 'var(--sapUiButtonActiveBorderColor)';
export const sapUiButtonLiteActiveBackground = 'var(--sapUiButtonLiteActiveBackground)';
export const sapUiSegmentedButtonActiveBackground = 'var(--sapUiSegmentedButtonActiveBackground)';
export const sapUiInfobarActiveBackground = 'var(--sapUiInfobarActiveBackground)';
export const sapUiLinkInverted = 'var(--sapUiLinkInverted)';
export const sapField_ReadOnly_Background = 'var(--sapField_ReadOnly_Background)';
export const sapUiButtonLiteActiveBorderColor = 'var(--sapUiButtonLiteActiveBorderColor)';
export const sapField_ReadOnly_HelpBackground = 'var(--sapField_ReadOnly_HelpBackground)';
export const sapUiFieldReadOnlyBackground = 'var(--sapUiFieldReadOnlyBackground)';
export const sapButton_Hover_Background = 'var(--sapButton_Hover_Background)';
export const sapBackgroundColorFade72 = 'var(--sapBackgroundColorFade72)';
export const sapScrollBar_TrackColor = 'var(--sapScrollBar_TrackColor)';
export const sapScrollBar_Hover_FaceColor = 'var(--sapScrollBar_Hover_FaceColor)';
export const sapUiFieldReadOnlyHelpBackground = 'var(--sapUiFieldReadOnlyHelpBackground)';
export const sapUiButtonRejectActiveBackgroundDarken5 = 'var(--sapUiButtonRejectActiveBackgroundDarken5)';
export const sapUiButtonRejectActiveBackgroundLighten5 = 'var(--sapUiButtonRejectActiveBackgroundLighten5)';
export const sapUiButtonAcceptActiveBackgroundDarken5 = 'var(--sapUiButtonAcceptActiveBackgroundDarken5)';
export const sapUiButtonAcceptActiveBackgroundLighten5 = 'var(--sapUiButtonAcceptActiveBackgroundLighten5)';
export const sapField_Hover_HelpBackground = 'var(--sapField_Hover_HelpBackground)';
export const sapUiButtonHoverBackground = 'var(--sapUiButtonHoverBackground)';
export const sapUiErrorBGLighten4 = 'var(--sapUiErrorBGLighten4)';
export const sapUiSuccessBGLighten5 = 'var(--sapUiSuccessBGLighten5)';
export const sapUiContentForegroundColorLighten5 = 'var(--sapUiContentForegroundColorLighten5)';
export const sapUiContentForegroundColorLighten7 = 'var(--sapUiContentForegroundColorLighten7)';
export const sapUiContentForegroundColorDarken3 = 'var(--sapUiContentForegroundColorDarken3)';
export const sapUiContentForegroundColorDarken5 = 'var(--sapUiContentForegroundColorDarken5)';
export const sapUiContentForegroundColorDarken10 = 'var(--sapUiContentForegroundColorDarken10)';
export const sapUiScrollBarTrackColor = 'var(--sapUiScrollBarTrackColor)';
export const sapUiScrollBarHoverFaceColor = 'var(--sapUiScrollBarHoverFaceColor)';
export const sapUiInfobarHoverBackground = 'var(--sapUiInfobarHoverBackground)';
export const sapUiListTableGroupHeaderBorderColor = 'var(--sapUiListTableGroupHeaderBorderColor)';
export const sapUiListTableFixedBorder = 'var(--sapUiListTableFixedBorder)';
export const sapUiListBorderColorLighten10 = 'var(--sapUiListBorderColorLighten10)';
export const sapUiSelectedDarken10 = 'var(--sapUiSelectedDarken10)';
export const sapUiActiveLighten3 = 'var(--sapUiActiveLighten3)';
export const sapUiFieldHoverHelpBackground = 'var(--sapUiFieldHoverHelpBackground)';
export const sapUiButtonLiteHoverBackground = 'var(--sapUiButtonLiteHoverBackground)';
export const sapUiSegmentedButtonHoverBackground = 'var(--sapUiSegmentedButtonHoverBackground)';
export const sapUiButtonHoverBorderColorLighten30 = 'var(--sapUiButtonHoverBorderColorLighten30)';
export const sapUiCalloutShadow = 'var(--sapUiCalloutShadow)';
export const sapUiShadowHeader = 'var(--sapUiShadowHeader)';
export const sapUiShadowLevel0 = 'var(--sapUiShadowLevel0)';
export const sapUiToggleButtonPressedHoverBackground = 'var(--sapUiToggleButtonPressedHoverBackground)';
export const sapUiButtonLiteHoverBorderColor = 'var(--sapUiButtonLiteHoverBorderColor)';
export const sapUiButtonFooterHoverBackground = 'var(--sapUiButtonFooterHoverBackground)';
export const sapUiSegmentedButtonFooterHoverBackground = 'var(--sapUiSegmentedButtonFooterHoverBackground)';
export const sapUiToggleButtonPressedHoverBorderColor = 'var(--sapUiToggleButtonPressedHoverBorderColor)';
export const sapUiSegmentedButtonSelectedHoverBackground = 'var(--sapUiSegmentedButtonSelectedHoverBackground)';
export const sapList_Hover_Background = 'var(--sapList_Hover_Background)';
export const sapUiSegmentedButtonSelectedHoverBorderColor = 'var(--sapUiSegmentedButtonSelectedHoverBorderColor)';
export const sapList_SelectionBackgroundColor = 'var(--sapList_SelectionBackgroundColor)';
export const sapUiListHoverBackground = 'var(--sapUiListHoverBackground)';
export const sapUiListSelectionBackgroundColor = 'var(--sapUiListSelectionBackgroundColor)';
export const sapUiButtonLiteActionSelectHoverBackground = 'var(--sapUiButtonLiteActionSelectHoverBackground)';
export const sapUiToggleButtonPressedBackgroundLighten50Desaturate47 =
  'var(--sapUiToggleButtonPressedBackgroundLighten50Desaturate47)';
export const sapUiButtonHoverBackgroundDarken2 = 'var(--sapUiButtonHoverBackgroundDarken2)';
export const sapUiButtonHoverBackgroundDarken5 = 'var(--sapUiButtonHoverBackgroundDarken5)';
export const sapUiToggleButtonPressedBorderColorLighten19Desaturate46 =
  'var(--sapUiToggleButtonPressedBorderColorLighten19Desaturate46)';
export const sapShell_TextColor = 'var(--sapShell_TextColor)';
export const sapButton_Emphasized_TextColor = 'var(--sapButton_Emphasized_TextColor)';
export const sapButton_TextColor = 'var(--sapButton_TextColor)';
export const sapPageHeader_TextColor = 'var(--sapPageHeader_TextColor)';
export const sapPageFooter_TextColor = 'var(--sapPageFooter_TextColor)';
export const sapTile_TitleTextColor = 'var(--sapTile_TitleTextColor)';
export const sapTile_TextColor = 'var(--sapTile_TextColor)';
export const sapTile_IconColor = 'var(--sapTile_IconColor)';
export const sapUiShellTextColor = 'var(--sapUiShellTextColor)';
export const sapUiShadowLevel1 = 'var(--sapUiShadowLevel1)';
export const sapUiShadowLevel2 = 'var(--sapUiShadowLevel2)';
export const sapUiShadowLevel3 = 'var(--sapUiShadowLevel3)';
export const sapUiButtonEmphasizedTextColor = 'var(--sapUiButtonEmphasizedTextColor)';
export const sapUiButtonTextColor = 'var(--sapUiButtonTextColor)';
export const sapUiPageHeaderTextColor = 'var(--sapUiPageHeaderTextColor)';
export const sapUiPageFooterBorderColor = 'var(--sapUiPageFooterBorderColor)';
export const sapUiPageFooterTextColor = 'var(--sapUiPageFooterTextColor)';
export const sapUiTileTitleTextColor = 'var(--sapUiTileTitleTextColor)';
export const sapUiTileTextColor = 'var(--sapUiTileTextColor)';
export const sapUiTileIconColor = 'var(--sapUiTileIconColor)';
export const sapUiButtonRejectTextColor = 'var(--sapUiButtonRejectTextColor)';
export const sapUiButtonAcceptTextColor = 'var(--sapUiButtonAcceptTextColor)';
export const sapUiButtonLiteTextColor = 'var(--sapUiButtonLiteTextColor)';
export const sapUiButtonHeaderTextColor = 'var(--sapUiButtonHeaderTextColor)';
export const sapUiButtonIconColor = 'var(--sapUiButtonIconColor)';
export const sapUiSegmentedButtonIconColor = 'var(--sapUiSegmentedButtonIconColor)';
export const sapUiButtonHeaderDisabledTextColor = 'var(--sapUiButtonHeaderDisabledTextColor)';
export const sapHighlightTextColor = 'var(--sapHighlightTextColor)';
export const sapGroup_TitleTextColor = 'var(--sapGroup_TitleTextColor)';
export const sapContent_ForegroundTextColor = 'var(--sapContent_ForegroundTextColor)';
export const sapUiShellActiveTextColor = 'var(--sapUiShellActiveTextColor)';
export const sapList_HeaderTextColor = 'var(--sapList_HeaderTextColor)';
export const sapUiHighlightTextColor = 'var(--sapUiHighlightTextColor)';
export const sapUiGroupTitleTextColor = 'var(--sapUiGroupTitleTextColor)';
export const sapUiContentForegroundTextColor = 'var(--sapUiContentForegroundTextColor)';
export const sapUiListTableGroupHeaderTextColor = 'var(--sapUiListTableGroupHeaderTextColor)';
export const sapUiListHeaderTextColor = 'var(--sapUiListHeaderTextColor)';
export const sapUiListActiveTextColor = 'var(--sapUiListActiveTextColor)';
export const sapUiSegmentedButtonSelectedIconColor = 'var(--sapUiSegmentedButtonSelectedIconColor)';
export const sapUiSegmentedButtonActiveIconColor = 'var(--sapUiSegmentedButtonActiveIconColor)';
export const sapButton_Hover_TextColor = 'var(--sapButton_Hover_TextColor)';
export const sapUiListSelectionHoverBackground = 'var(--sapUiListSelectionHoverBackground)';
export const sapUiButtonHoverTextColor = 'var(--sapUiButtonHoverTextColor)';
export const sapUiButtonFooterTextColor = 'var(--sapUiButtonFooterTextColor)';
export const sapUiSegmentedButtonTextColor = 'var(--sapUiSegmentedButtonTextColor)';
export const sapUiToggleButtonPressedTextColor = 'var(--sapUiToggleButtonPressedTextColor)';
export const sapUiButtonActiveTextColor = 'var(--sapUiButtonActiveTextColor)';
export const sapUiSegmentedButtonSelectedTextColor = 'var(--sapUiSegmentedButtonSelectedTextColor)';
export const sapUiSegmentedButtonActiveTextColor = 'var(--sapUiSegmentedButtonActiveTextColor)';
